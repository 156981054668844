export const pets: string[] = [
  "Pet chaos elemental",
  "Pet dagannoth supreme",
  "Pet dagannoth prime",
  "Pet dagannoth rex",
  "Pet penance queen",
  "Pet kree'arra",
  "Pet general graardor",
  "Pet zilyana",
  "Pet k'ril tsutsaroth",
  "Baby mole",
  "Prince black dragon",
  "Kalphite princess",
  "Pet smoke devil",
  "Pet kraken",
  "Pet dark core",
  "Pet snakeling",
  "Chompy chick",
  "Venenatis spiderling",
  "Callisto cub",
  "Vet'ion jr.",
  "Scorpia's offspring",
  "Tzrek-jad",
  "Hellpuppy",
  "Abyssal orphan",
  "Heron",
  "Rock golem",
  "Beaver",
  "Baby chinchompa",
  "Bloodhound",
  "Giant squirrel",
  "Tangleroot",
  "Rift guardian",
  "Rocky",
  "Phoenix",
  "Olmlet",
  "Skotos",
  "Jal-nib-rek",
  "Herbi",
  "Noon",
  "Vorki",
  "Lil' zik",
  "Ikkle hydra",
  "Sraracha",
  "Youngllef",
  "Smolcano",
  "Little nightmare",
  "Lil' creator",
  "Tiny tempor",
  "Nexling",
  "Abyssal protector",
];
