export const loadouts = [
  {
    name: "Purple Peddler",
    tagline: "u/Mary_RS",
    category: "pets",
    background_image: "https://i.redd.it/gqbv9vs7d0u81.png",
    loadout_name: "Purple Peddler",
    loadout: {
      head: "12311",
      neck: "9470",
      body: "10416",
      legs: "10418",
      feet: "25557",
      shield: "12612",
      pet: "22473",
    },
  },
  {
    name: "Who let the dogs out?",
    tagline: "Who, who who?",
    category: "pets",
    background_image:
      "https://preview.redd.it/b3fjbbyofyu81.png?width=640&crop=smart&auto=webp&s=edb880333d474f080a641b7d2b76d5f17b8806f7",
    loadout_name: "Who let the dogs out?",
    loadout: {
      head: "24527",
      cape: "23859",
      pet: "13247",
    },
  },
  {
    name: "A Dragonkin's Vengeance",
    tagline: "Fus roh dah!",
    category: "pets",
    background_image:
      "https://preview.redd.it/30wqnqzyowq81.jpg?width=640&crop=smart&auto=webp&s=3c08d67e8b1d4391e20085983a5ca1a59d805b04",
    loadout_name: "A Dragonkin's Vengeance",
    loadout: {
      head: "20029",
      neck: "24780",
      body: "20517",
      legs: "4087",
      feet: "23294",
      pet: "21358",
      weapon: "13652",
    },
  },
  {
    name: "Attack - Slash",
    tagline: "...and Bandos was his name-o",
    category: "bis",
    background_image: "https://i.imgur.com/Xc089cL.png",
    loadout_name: "BIS: Attack slash 🤺",
    loadout: {
      head: "3753",
      neck: "19553",
      cape: "6570",
      body: "11832",
      legs: "11834",
      feet: "13239",
      ring: "11772",
      hands: "22981",
      ammo: "20220",
      weapon: "23995",
      shield: "22322",
    },
  },
  {
    name: "Attack - Ranged",
    tagline: "Take from the poor and give to the rich",
    loadout_name: "BIS: Attack ranged",
    category: "bis",
    background_image: "https://i.imgur.com/9klTDyL.png",
    loadout: {
      ammo: "21905",
      body: "11828",
      cape: "22109",
      feet: "13237",
      hands: "7462",
      head: "11826",
      legs: "11830",
      neck: "19547",
      ring: "11771",
      shield: "21000",
      weapon: "11785",
    },
  },
  {
    name: "Attack - Magic",
    tagline: "Channel your inner Merlin",
    loadout_name: "BIS: Attack magic",
    category: "bis",
    background_image: "https://i.imgur.com/69Meg7E.png",
    loadout: {
      head: 21018,
      neck: 12002,
      cape: 21791,
      ammo: 20220,
      weapon: 21006,
      body: 21021,
      shield: 12825,
      legs: 21024,
      feet: 13235,
      ring: 11770,
      hands: 19544,
    },
  },
  {
    name: "Graceful tunes 🎶",
    tagline: "Music to your ears",
    loadout_name: "Graceful tunes 🎶",
    category: "emo",
    background_image: "https://i.imgur.com/049tv3l.png",
    loadout: {
      body: "13072",
      cape: "13222",
      feet: "13677",
      hands: "13676",
      head: "12321",
      legs: "13673",
      neck: "9470",
      shield: "12335",
      weapon: "20733",
    },
  },
  {
    name: "My Sleep Paralysis Demon",
    tagline: "u/Jack_The_Mad",
    loadout_name: "Graceful tunes",
    category: "emo",
    background_image: "https://i.redd.it/jseornb45ot41.png",
    loadout: {
      shield: 24430,
      head: 23448,
      neck: 22692,
      body: 12888,
      weapon: 7449,
      cape: 7918,
      feet: 22701,
      hands: 3060,
    },
  },
  {
    name: "Post Max 3rd Age",
    tagline: "u/GravessOSRS",
    loadout_name: "Post Max 3rd Age",
    category: "emo",
    background_image: "https://i.redd.it/us3dmvw9rdr41.png",
    loadout: {
      head: 24370,
      body: 10348,
      legs: 10332,
      shield: 12817,
      feet: 13677,
      weapon: 9013,
      hands: 22981,
      cape: 21898,
      neck: 12018,
    },
  },
  {
    name: "Test 1",
    tagline: "",
    loadout_name: "Test 1",
    category: "pet",
    background_image: "https://i.imgur.com/9klTDyL.png",
    loadout: {
      body: "13072",
      cape: "13222",
      feet: "13677",
      hands: "13676",
      head: "12321",
      legs: "13673",
      neck: "9470",
      shield: "12335",
      weapon: "20733",
    },
  },
  {
    name: "Test 1 2",
    tagline: "",
    loadout_name: "Test 1",
    category: "pet",
    background_image: "https://i.imgur.com/9klTDyL.png",
    loadout: {
      body: "13072",
      cape: "13222",
      feet: "13677",
      hands: "13676",
      head: "12321",
      legs: "13673",
      neck: "9470",
      shield: "12335",
      weapon: "20733",
    },
  },
  {
    name: "Charlette's Web 🕷",
    tagline: "",
    loadout_name: "Charlette's Web 🕷",
    category: "pet",
    background_image: "https://i.redd.it/h7m8fsr1q8021.png",
    loadout: {
      body: "13072",
      cape: "13222",
      feet: "13677",
      hands: "13676",
      head: "12321",
      legs: "13673",
      neck: "9470",
      shield: "12335",
      weapon: "20733",
    },
  },
  {
    name: "Test 2",
    tagline: "",
    loadout_name: "Test 2",
    category: "skill",
    background_image: "https://i.imgur.com/9klTDyL.png",
    loadout: {
      body: "13072",
      cape: "13222",
      feet: "13677",
      hands: "13676",
      head: "12321",
      legs: "13673",
      neck: "9470",
      shield: "12335",
      weapon: "20733",
    },
  },
  {
    name: "Test 2 2",
    tagline: "",
    loadout_name: "Test 2",
    category: "skill",
    background_image: "https://i.imgur.com/9klTDyL.png",
    loadout: {
      body: "13072",
      cape: "13222",
      feet: "13677",
      hands: "13676",
      head: "12321",
      legs: "13673",
      neck: "9470",
      shield: "12335",
      weapon: "20733",
    },
  },
  {
    name: "Test 2 3",
    tagline: "",
    loadout_name: "Test 2",
    category: "skill",
    background_image: "https://i.imgur.com/9klTDyL.png",
    loadout: {
      body: "13072",
      cape: "13222",
      feet: "13677",
      hands: "13676",
      head: "12321",
      legs: "13673",
      neck: "9470",
      shield: "12335",
      weapon: "20733",
    },
  },
  {
    name: "Elven Swag",
    tagline: "u/straightchbe",
    loadout_name: "Elven Swag",
    category: "fashionscape",
    background_image:
      "https://preview.redd.it/c0rkqfyy6ue31.jpg?width=960&crop=smart&auto=webp&s=3778b0d73c9658dc425b11da5f8cb8ecebd38bb1",
    loadout: {
      head: 21900,
      body: 23975,
      feet: 21733,
      neck: 12436,
      shield: 12817,
      weapon: 23680,
      hands: 13103,
      legs: 23979,
      cape: 13068,
    },
  },
  {
    name: "The Orange Assassin",
    tagline: "Don't tap it, whack it (quietly) - u/metalslug53",
    loadout_name: "The Orange Assassin",
    category: "fashionscape",
    background_image:
      "https://external-preview.redd.it/6448xDbtvJ2j5y3zOgk0QPy44dI6HaRVCrmGi1JFi3s.png?auto=webp&s=20b5cebb4c5bb89502bf8806837f055e4ab6b44e",
    loadout: {
      head: 11663,
      cape: 9790,
      neck: 22400,
      weapon: 12773,
      body: 23097,
      shield: 20714,
      legs: 13360,
      hands: 23091,
      feet: 9638,
    },
  },
  {
    name: "Praise Guthix",
    tagline: "u/RsEzey",
    loadout_name: "Praise Guthix",
    category: "fashionscape",
    background_image: "https://i.redd.it/2epmwpk88zf41.png",
    loadout: {
      head: 12639,
      body: 10330,
      legs: 10332,
      cape: 13069,
      feet: 19927,
      weapon: 24144,
      shield: 12807,
      neck: 13393,
      hands: 3799,
    },
  },
];
