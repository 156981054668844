import React from "react";

export const loadouts = [
  {
    equipment: {
      head: "id",
    },
    inventory: ["id1", "id2"],
  },
];

const LoadoutList = () => {
  return <p>List</p>;
};

export default LoadoutList;
